

.building-detail-header{
    background-image: url('./assets/detail-image.png');
    background-size: cover;
    width:100%;
    height: 170px;
}

.building-info-section{
    color: black;
}