

/*
.name-form,
.mail-form{
    height: 100px;
}*/

.home-letter::after{
   margin:0 .5em;
    content:">";
}

/*
.name-label,
.mail-label,
.message-label::after{
   margin-left:.5em;
   content:"*";
   color:crimson;
}*/