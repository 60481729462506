
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: 'white';
  font-family: Poppins;
  font-size: 1.2rem;
  color: white;
  min-height: 100vh;
  height: auto;
  width:100vw;
}

.header-home{
  background-image: url('./assets/cube.png');
  
  width:100%;
  /*height:800px;*/
  background-size: cover;
}

.building-section{
  background-image: url('./assets/house.png');
  background-size:cover;
  width:80vw;
  height:auto;
}

.service-section{
  background-image: url('./assets/service-image.png');
  background-size: cover;
  width: 80vw;
  height:auto;
}

.aboutus-section{
  background-image: url('./assets/aboutus-image.png');
  background-size:cover;
  width: 38vw;
  height:30vh;
}

.contact-section{
  background-image: url('./assets/contact-image.png');
  background-image: auto;
  width:38vw;
  height:30vh;
}

.main-footer{
  background-color: #1F1F1F;
  width:100%;
  height: 30vh;
}


.fadeIn {
  animation: fadeIn 500ms linear both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}